<template>
    <header class="d-flex">
        <span><router-link to="/ticket-listing"><img src="../assets/images/arrow-left.svg"></router-link></span>
        <h1>Response</h1>
        <span><router-link to="" @click="logout()"><LogOutFrontSvgIcon /></router-link></span>
    </header> 
    <div class="form_wrap">
        <form method="POST" enctype="multipart/form-data" >
            <label class="d-flex flex-column field_group">
                <span>UserID</span>
                <input type="text" name="user_id" v-model="v$.form.user_id.$model" value="" class="forminput" placeholder="Enter userID" readonly>
            </label>
            <label class="d-flex flex-column field_group">
                <span>Issue (enter your query)</span>
                <textarea name="issue" v-model="v$.form.issue.$model" class="forminputx" placeholder="Enter issue" v-on:keyup="textareaFun" id="textarea"/>
            </label>
            <file-pond
                name="file"
                v-model="file"
                 ref="pond"
                label-idle="Drop files here..."
                v-bind:allow-multiple="true"
                accepted-file-types="image/jpeg, image/png, application/pdf, application/zip"
                instantUpload=false
                max-files="5"
                v-bind:files="myFiles"
                @updatefiles="updateFiles"
            />
        </form>
    </div>
    <footer>
        <button class="field_btn" v-on:click="submitTicket" :disabled="v$.form.$invalid" v-if="loginLoader === 1">Send</button>
        <button class="field_btn" :disabled="v$.form.$invalid" v-else><span class="spinner-border mr-2 spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...</button>
    </footer>
</template>
<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import LogOutFrontSvgIcon from '@/components/Widgets/Icon/LogOutFrontSvgIcon.vue';
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import {
    required,
    helpers,
    minLength
} from '@vuelidate/validators'
import {
    encodeBase62,
    decodeBase62,
} from '../helper'
    export default {
        name: 'AddResponsePage',
        components: {
            FilePond,
            LogOutFrontSvgIcon,
        },
        setup() {
            return { v$: useVuelidate() }
        },
        data() {
            return {
                loginLoader: 1,
                file: [], // Holds uploaded files
                files: [],
                myFiles: [],
                errorMessage: '',
                errorRes: '',
                form: {
                    user_id: '',
                    issue: ''
                }
            }
        },
        async mounted() { 
            this.v$.form.user_id.$model = localStorage.getItem('supportSystemNameFront')
            if (this.$route.params.ticketId == '') {
                this.$router.push({ path: '/ticket-listing' })
            }else{
                this.ticketId = this.decodeBase62(this.$route.params.ticketId);
            }
        },
        methods: {
            encodeBase62,
            decodeBase62,
            textareaFun() {
                var textarea = document.getElementById("textarea");
                textarea.style.height = "";
                textarea.style.height = Math.min(textarea.scrollHeight, 200) + "px";
            },
            startLoader () {
                this.$Progress.start()
            },
            finishLoader () {
                this.$Progress.finish()
            },
            updateFiles(newFiles) {            
                this.files = newFiles.map((file) => file.file);
            },
            logout(){
                localStorage.removeItem("supportSystemFront");
                localStorage.removeItem("supportSystemNameFront");
                localStorage.removeItem("supportSystemMPinFront");
                localStorage.removeItem("setTime");
                this.$store.state.frontUser.userInfo = ''
                this.$store.state.frontUser._id = ''
                this.$router.push({ path: '/' })
            },
            async submitTicket() {
                this.startLoader()    
                this.loginLoader = 2;
                const fd = new FormData;
                fd.append('response_id', this.ticketId)
                fd.append('response_desc', this.form.issue)
                this.files.forEach((file) => {
                    fd.append("files", file);
                });
                await axios.post(process.env.VUE_APP_FRONT_API_URL+'ticket-response/create', fd)
                .then((resp) => {
                        if (resp.status == 200 && resp.data.status == 'success') {
                            this.$store.state.loadUserInfoData = 2
                            this.$toast.success(resp.data.message);
                            this.$store.state.loadUserInfoData = 1
                            this.$router.push({ path: `/ticket-detail/${encodeBase62(this.ticketId)}` });

                        } else if (resp.status == 200 && resp.data.status == 'failure') {
                            this.loginLoader = 1;
                            this.$toast.error(resp.data.message);
                        } else if (resp.status == 401) {
                            this.loginLoader = 1;
                            this.$toast.error(resp.data.message);
                        }
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.errorRes = error.response.data.message;
                })
            }
        },
        validations() {
            return {
                form: {
                    user_id: {
                        required: helpers.withMessage('Please enter user id', required),
                        min: minLength(2),
                        noSpaces: helpers.withMessage('Spaces are not allowed', (value) => !/\s/.test(value))
                    },
                    issue: {
                        required,
                        min: minLength(2),
                    }
                },
            }
        },
    }
</script>