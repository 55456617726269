import { createRouter, createWebHistory } from "vue-router";
import Login from "@/fontend/Login.vue";
import Main from "@/fontend/Main.vue";
import Ticket_listing from "@/fontend/Ticket_listing.vue";
import Create_ticket from "@/fontend/Ticket_create.vue";
import Ticket_submit from "@/fontend/Ticket_submit.vue";
import Ticket_detail from "@/fontend/Ticket_detail.vue";
import Add_response from "@/fontend/Add_response.vue";

// import Dashboard from "@/backend/Dashboard.vue";
// import AdminLogin from "@/backend/AdminLogin.vue";
// import Priority from "@/backend/components/priority/Priority.vue";
// import Labels from "@/backend/components/labels/Labels.vue";
// import Role from "@/backend/components/role/Role.vue";
// import TeamMember from "@/backend/TeamMember.vue";
// import Customers from "@/backend/components/Customers/index.vue"
// import TicketListing from "../backend/components/Tickets/index.vue"
// import TicketDetail from "../backend/components/Tickets/detail.vue"

import store from '../vuex/store.js'; 
import PageNotFound from "../pages/PageNotFound.vue"
const routes = [
    {
        name: "Login",
        component: Login,
        path: "/",
        prefix: "auth",
        meta: { requiresAuth: false }
    },
    {
        name: "Main",
        component: Main,
        path: "/main",
        prefix: "auth",
        meta: { requiresAuth: false }
    },
    {
        name: "Ticket_listing",
        component: Ticket_listing,
        path: "/ticket-listing",
        meta: { requiresAuth: false }
    },
    {
        name: "Create_ticket",
        component: Create_ticket,
        path: "/ticket-create",
        meta: { requiresAuth: false }
    },
    {
        name: "Ticket_submit",
        component: Ticket_submit,
        path: "/ticket-submit",
        meta: { requiresAuth: false }
    },
    {
        name: "Ticket_detail",
        component: Ticket_detail,
        path: "/ticket-detail/:ticketId?",
    },
    {
        name: "Add_response",
        component: Add_response,
        path: "/add-response/:ticketId?",
    },
    // {
    //     name: "Dashboard",
    //     component: Dashboard,
    //     path: "/admin/dashboard",
    //     meta: { requiresAuth: true }
    // },
    // {
    //     name: "DashboardMain",
    //     component: Dashboard,
    //     path: "/admin/",
    //     meta: { requiresAuth: true }
    // },
    // {
    //     name: "AdminLogin",
    //     component: AdminLogin,
    //     path: "/admin/login",
    //     meta: { requiresAuth: true }
    // },
    // {
    //     name: "Priority",
    //     component: Priority,
    //     path: "/admin/priority",
    //     meta: { requiresAuth: true }
    // },
    // {
    //     name: "TeamMember",
    //     component: TeamMember,
    //     path: "/admin/team-member",
    //     meta: { requiresAuth: true }
    // },
    // {
    //     name: "Customers",
    //     component: Customers,
    //     path: "/admin/customers",
    //     meta: { requiresAuth: true }
    // },
    // {
    //     name: "TicketListing",
    //     component: TicketListing,
    //     path: "/admin/tickets",
    //     meta: { requiresAuth: true }
    // },
    // {
    //     name: "TicketDetail",
    //     component: TicketDetail,
    //     path: "/admin/tickets-detail/:ticketId?",
    //     meta: { requiresAuth: true }
    // },
    // {
    //     name: "Labels",
    //     component: Labels,
    //     path: "/admin/labels",
    //     meta: { requiresAuth: true }
    // },
    // {
    //     name: "Role",
    //     component: Role,
    //     path: "/admin/role",
    //     meta: { requiresAuth: true }
    // },
    {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound
    },
]

let router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes,
});


router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth == true) {
        next();
    } else {
        if(store.state.isAdmin == 2){
            next('/admin')       
        }else{
            next();
        }
    }
})

export default router;
