export const FrontUserModule = {
    namespaced: true,
    state: {
        accessToken: "",
        isFrontLogin: 1,
        isActiveToken: "",
        _id: null,
        userInfo: {},
        ticketRecords: {},
        totalRecord: 0,
        loadUserInfoData: 1,
        loadTdData: 1
    }
}