<template>
    <header class="d-flex">
        <span><router-link to="/ticket-listing"><img src="../assets/images/arrow-left.svg"></router-link></span>
        <h1>Ticket Details</h1>
        <span><router-link to="" @click="logout()"><LogOutFrontSvgIcon /></router-link></span>
    </header> 
    <div class="form_wrap forscrolleffect">
        <div class="box_for_ticket_details">
            <div class="box_for_ticket_details_inner d-flex w-100 flex-column" v-if="$store.state.frontUser.loadTdData == 3">
                <div class="box_for_ticket_details_row d-flex w-100">
                    <label class="d-flex w-100 align-items-center justify-content-between">
                        <span>Requester</span>
                        <p>{{ticketInfo.user_id}}</p>
                    </label> 
                </div>
                <div class="box_for_ticket_details_row d-flex w-100">
                    <label class="d-flex w-100 align-items-center justify-content-between">
                        <span>Submitted</span>
                        <p  v-b-tooltip.hover v-bind:title="formatDateTime(ticketInfo.createdAt)">
                            <timeago v-if="ticketInfo.createdAt" :datetime="new Date(ticketInfo.createdAt)" />
                        </p>
                    </label> 
                </div>
                <div class="box_for_ticket_details_row d-flex w-100">
                    <label class="d-flex w-100 align-items-center justify-content-between">
                        <span>Ticket ID</span>
                        <p>#{{ticketInfo.ticket_id}}</p>
                    </label> 
                </div>
                <div class="box_for_ticket_details_row d-flex w-100">
                    <label class="d-flex w-100 align-items-center justify-content-between">
                        <span>Ticket Status</span>
                        <p>{{ticketInfo.label}}</p>
                    </label> 
                </div>
            </div>
            <!-- ----shimmar this one------- -->
            <div class="box_for_ticket_details_inner d-flex w-100 flex-column" v-if="$store.state.frontUser.loadTdData == 2">
                <div class="box_for_ticket_details_row d-flex w-100">
                    <label class="d-flex w-100 align-items-center justify-content-between">
                        <span class="stroke animate title w-30"></span>
                        <p class="stroke animate title w-50"></p>
                    </label> 
                </div>
                <div class="box_for_ticket_details_row d-flex w-100">
                    <label class="d-flex w-100 align-items-center justify-content-between">
                        <span class="stroke animate title w-20"></span>
                        <p class="stroke animate title w-40"></p>
                    </label> 
                </div>
                <div class="box_for_ticket_details_row d-flex w-100">
                    <label class="d-flex w-100 align-items-center justify-content-between">
                        <span class="stroke animate title w-25"></span>
                        <p class="stroke animate title w-25"></p>
                    </label> 
                </div>
                <div class="box_for_ticket_details_row d-flex w-100">
                    <label class="d-flex w-100 align-items-center justify-content-between">
                        <span class="stroke animate title w-25"></span>
                        <p class="stroke animate title w-25"></p>
                    </label> 
                </div>
            </div>




            <div class="query_and_response_main" v-if="$store.state.frontUser.loadTdData == 3">
                <div class="query_and_response_inner">
                    <div class="responder_datetime">
                        <!-- <p class="date w-100 text-right" v-b-tooltip.hover v-bind:title="formatDateTime(ticketInfo.createdAt)">
                            <timeago v-if="ticketInfo.createdAt" :datetime="new Date(ticketInfo.createdAt)" />
                        </p> -->
                        <h6>{{ ticketInfo.title }}</h6>
                    </div>     
                    <p class="cursor" v-html="formatIssue(isExpanded[111] ? ticketInfo.issue : truncatedText(ticketInfo.issue))" @click="toggleExpand(111)"></p>
                    <button v-if="ticketInfo.issue && ticketInfo.issue.length > limit"  @click="toggleExpand(111)" class="d-none">
                    {{ isExpanded[111] ? "Read Less" : "Read More" }}
                    </button>     
                    <ul >
                        <li v-for="(item) in ticketInfo.attachments" :key="item._id" >
                            <a :href="img_url + item" download target="_blank" class="pdf-download"  v-if="item.toLowerCase().endsWith('.pdf')">
                                <img class="cursor" src="../assets/images/default-pdf.png"  alt="Download PDF">
                                <span>Download PDF</span>
                            </a>
                            <img class="cursor" :class="'img'+item" :src="getImageSrc(item)" @error="imageLoadError(item)" @click="showSingle(img_url+''+item)" v-else>
                        </li>
                    </ul>
                </div>
                <div class="query_and_response_inner " v-for="(item) in ticketInfo.responses" :key="item._id" :class="item.is_user == 0 ? 'userResponder' : 'responder'">
                    <div class="responder_datetime">
                        <h6>{{item.is_user == 0 ? $store.state.frontUser.userInfo.user_id : 'Admin' }}</h6>
                        <p class="m-0" v-b-tooltip.hover v-bind:title="formatDateTime(item.createdAt)"><timeago :datetime="item.createdAt"/></p>
                    </div>
                    <p class="cursor" v-html="formatIssue(isExpanded[item._id] ? item.response : truncatedText(item.response))" @click="toggleExpand(item._id)"></p>
                    <button v-if="item.response.length > limit" @click="toggleExpand(item._id)" class="d-none">
                        {{ isExpanded[item._id] ? "Read Less" : "Read More" }}
                    </button>   
                    <ul v-if="item.attachments.length >0">
                        <li v-for="(itemLi) in item.attachments" :key="itemLi._id">
                            <a :href="img_url + itemLi" download target="_blank" class="pdf-download"  v-if="itemLi.toLowerCase().endsWith('.pdf')">
                                <img class="cursor" src="../assets/images/default-pdf.png"  alt="Download PDF">
                                <span>Download PDF</span>
                            </a>
                            <img class="cursor" :class="'img'+itemLi" :src="getImageSrc(itemLi)" @error="imageLoadError(itemLi)" @click="showSingle(img_url+''+itemLi)" v-else>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- ----shimmar this one------- -->
            <div class="query_and_response_main sethghshmr" v-if="$store.state.frontUser.loadTdData == 2">
                <div class="query_and_response_inner userResponder">
                    <div class="responder_datetime stroke animate title w-100"></div>
                    <p class="stroke animate title w-30 mt-2 mb-0"></p>
                </div>
                <div class="query_and_response_inner userResponder">
                    <div class="responder_datetime stroke animate title w-100"></div>
                    <p class="stroke animate title w-30 mt-2 mb-0"></p>
                </div>
                <div class="query_and_response_inner userResponder">
                    <div class="responder_datetime stroke animate title w-100"></div>
                    <p class="stroke animate title w-30 mt-2 mb-0"></p>
                </div>
                <div class="query_and_response_inner userResponder">
                    <div class="responder_datetime stroke animate title w-100"></div>
                    <p class="stroke animate title w-30 mt-2 mb-0"></p>
                </div>
            </div>
        </div>
    </div>
    <footer>
        <router-link :to="`/add-response/${encodeBase62(ticketId)}`" class="field_btn">Add Response</router-link>
    </footer>
    <vue-easy-lightbox
        :visible="visibleRef"
        :imgs="imgsRef"
        :index="indexRef"
        @hide="onHide"
        ></vue-easy-lightbox>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import axios from 'axios'
import LogOutFrontSvgIcon from '@/components/Widgets/Icon/LogOutFrontSvgIcon.vue';
import {
    ref
} from 'vue';
import $ from "jquery";
import {
    encodeBase62,
    decodeBase62,
    formatDateTime,
} from '../helper'

    export default {
        name: 'TicketDetailPage',
        components: {
            VueEasyLightbox,
            LogOutFrontSvgIcon,
        },
        data() {
            return {
                img_url: process.env.VUE_APP_IMG_URL,
                ticketInfo: [],
                ticketId: '',
                isExpanded:  {},
                limit: 150,
            }
        },
        async mounted() { 
            if (this.$route.params.ticketId == '') {
                this.$router.push({ path: '/ticket-listing' })
            }else{
                this.ticketId = this.decodeBase62(this.$route.params.ticketId);
                this.ticketDetail();
            }
        },
        computed: {
            formattedText(issue) {
                return issue;
            },
            iRt() {
                return this.$store.state.loadUserInfoData
            },
        },
        watch: {
            async iRt(newValue) {
                await this.ticketDetail()
                return newValue
            },
        },
        methods: {
            encodeBase62,
            decodeBase62,
            formatDateTime,
            startLoader () {
                this.$Progress.start()
            },
            finishLoader () {
                this.$Progress.finish()
            },
            imageLoadError(item) {
                console.log('Image failed to load');
                $('.userImgSideCls').hide('.img',item);
            }, 
            formatIssue(text) {
                if (!text) return ""; // Prevent errors if issue is undefined or null
                return text.replace(/\r\n|\n/g, "<br>");
            },
            toggleExpand(id) {
                this.isExpanded = { ...this.isExpanded, [id]: !this.isExpanded[id] };
                // this.$set(this.isExpanded, id, !this.isExpanded[id]); // Toggle state per ticket
            },
            truncatedText(issue) {
                if (!issue || typeof issue !== "string") {
                    return ""; // Handle undefined/null cases
                }
                // return issue.substring(0, this.limit) + " ..." +this.limit;
                return issue.length > this.limit ? issue.substring(0, this.limit) + "... <span class='readmorecls'>Read more</span>" : issue;
            },
            getImageSrc(filePath) {
                if (filePath.toLowerCase().endsWith('.pdf')) {
                    return require('@/assets/images/default-pdf.png');
                }
                return this.img_url + filePath; // Return original image path
            },
            logout(){
                localStorage.removeItem("supportSystemFront");
                localStorage.removeItem("supportSystemNameFront");
                localStorage.removeItem("supportSystemMPinFront");
                localStorage.removeItem("setTime");
                this.$store.state.frontUser.userInfo = ''
                this.$store.state.frontUser._id = ''
                this.$router.push({ path: '/' })
            },
            async ticketDetail() 
            {
                if(this.ticketId && this.$store.state.frontUser.userInfo._id){
                    this.$store.state.frontUser.loadTdData = 2;
                    this.startLoader()
                    await axios.post(process.env.VUE_APP_FRONT_API_URL + 'ticket/detail', {
                        ticket_id: this.ticketId,
                        user_id: this.$store.state.frontUser.userInfo._id
                    })
                    .then((resp) => {
                        if (resp.status == 200 && resp.data.status == 'success') {
                            this.ticketInfo = resp.data.data
                        } else if (resp.status == 200 && resp.data.status == 'failure') {
                            this.$toast.error(resp.data.message);
                        } else if (resp.status == 401) {
                            this.$toast.error(resp.data.message);
                        }
                        this.$store.state.frontUser.loadTdData = 3;
                        this.finishLoader()
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        this.$store.state.user.accessToken = ''
                        this.$store.state.user.userInfo = ''
                        localStorage.removeItem("userInfo");
                        localStorage.removeItem("token");    
                        this.$router.push({
                            name: 'Login'
                        })
                    })
                }
            },
        },
        setup() {
            const visibleRef = ref(false)
            const indexRef = ref(0)
            const imgsRef = ref([])
            const onShow = () => {
                visibleRef.value = true
            }  
            const showSingle = (itemCmt) => {
                imgsRef.value = [
                { title: '', src: itemCmt },
                // { title: itemCmt.file_name, src: itemCmt.file_url },
                ]
                onShow()
            }
            const onHide = () => (visibleRef.value = false)
            return {
                visibleRef,
                indexRef,
                imgsRef,
                showSingle,
                onHide
            };
        },
    }
</script>