<template>
<div class="common-app d-flex flex-column vh-100 align-items-center">
    <div class="common-app-inner">
        <router-view :key="$route.fullPath" />
        <vue-progress-bar></vue-progress-bar>
    </div> 
    <UserInfo />
</div> 
</template>
<script>
// import axios from 'axios'
import UserInfo from './UserInfo.vue';
    export default {
        name: 'FrontPage',
        components: {
            UserInfo
        },
        // async mounted() { 
        //     let token = localStorage.getItem('supportSystemAdmin');
        //     if (token) {
        //         // this.$router.push({ path: '/admin' })
        //     }
        //     if(this.$store.state.isAdmin == 2){
        //         // this.$router.push({
        //         //     name: 'Admin'
        //         // })
        //     }   
        // },
        computed: {
            iRt() {
                return this.$store.state.isRefreshToken
            },
        },
        watch: {
            async iRt(newValue) {
                await this.refreshToken()
                return newValue
            },
        },
        async mounted() { 
            this.$store.state.isAdmin = 1   
            this.refreshToken()
        },
        methods: {
            startLoader () {
                this.$Progress.start()
            },
            finishLoader () {
                this.$Progress.finish()
            },
            async refreshToken() {
                if (this.$store.state.frontUser.isActiveToken == '') {
                    let token = localStorage.getItem('supportSystemFront');
                    // let tokenName = localStorage.getItem('supportSystemNameFront');
                    if (token) {
                        this.$store.state.loadUserInfoData = 2
                    }else{
                        // this.$toast.success('Your login session has expired for security reasons. Please log in again to continue.')
                        this.$router.push({
                            path: '/'
                        })
                    }
                }
            }
        },
    }
</script>