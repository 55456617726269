<template>
    <header>
        <h1><img src="../assets/images/logo.svg" alt=""></h1>
    </header>  
    <form @submit.prevent="submitForm" @keydown.enter="recaptchaAndSignIn" >
        <div class="form_wrap">
            <label class="d-flex flex-column field_group">
                <span>Please Enter UserID</span>
                <input type="text" name="user_id" class="forminput" id="user_id" placeholder="Enter userID" v-model="v$.form.user_id.$model" autofocus>                           
                <div class="input-errors" v-for="(error, index) of v$.form.user_id.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </label>
            <label class="d-flex flex-column field_group">
                <span class="d-flex flex-column">Please Enter Pin <i class="spanInfoCls">(You can add your existing pin or create new PIN)</i></span>
                <div class="pin-inputs">
                <input
                    v-for="(digit, index) in form.pin"
                    :key="index"
                    ref="pinInputs"
                    type="password"
                    class="forminput"
                    :class="'formInputCls-'+index"
                    maxlength="1"
                    v-model="form.pin[index]"
                    @input="moveFocus(index)"
                    @keydown.backspace="handleBackspace(index)"
                />
                </div>
                <div class="input-errors" v-if="v$.form.pin.$error">
                    <div class="error-msg">{{ v$.form.pin.$errors[0].$message }}</div>
                </div>
            </label>
        </div>
        <!-- <button @click="recaptcha">Execute recaptcha</button> -->
        <footer>
            <button class="field_btn" type="submit" v-on:click="recaptchaAndSignIn" :disabled="v$.form.$invalid" v-if="loginLoader === 1">Login</button>
            <button class="field_btn" :disabled="v$.form.$invalid" v-else><span class="spinner-border mr-2 spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...</button>
        </footer>
    </form>
</template>
<script>
import { useReCaptcha } from "vue-recaptcha-v3";
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import $ from "jquery";
import {
    required,
    helpers,
    minLength,
    maxLength
} from '@vuelidate/validators'
import 
    { 
        setItemWithExpiry,
    }
 from '../helper'
 import { onMounted } from "vue";
    export default {
        name: 'LoginPage',
        setup() {
            const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

            const recaptcha = async () => {
                await recaptchaLoaded();
                await executeRecaptcha("login");
            };

            return {
                recaptcha,
                v$: useVuelidate()
            };
        },
        computed: {
            mpinString: {
                get() {
                    return this.form.pin.join(""); // Convert array to string
                },
                set(value) {
                    this.form.pin = value.split(""); // Convert string to array
                }
            }
        },
        data() {
            return {
                loginLoader: 1,
                errorMessage: '',
                errorRes: '',
                form: {
                    user_id: '',
                    mpin: '',
                    pin: ["", "", "", ""],
                }
            }
        },
        async mounted() { 
            let token = localStorage.getItem('supportSystemFront');
            let tokenName = localStorage.getItem('supportSystemNameFront');
            if(this.$store.state.isAdmin != 1){
                this.$router.push({ path: '/admin/login' })
            }
            if (token && tokenName) {
                this.$router.push({ path: '/ticket-listing' })
            }
        },
        methods: {
            setItemWithExpiry,
            startLoader () {
                this.$Progress.start()
            },
            finishLoader () {
                this.$Progress.finish()
            },
            isInteger(value) {
                    return /^\d+$/.test(value);
                },
            moveFocus(index) {
                // If user enters a digit, move to next input
                if (this.form.pin[index] && index < 3) {
                    var formInputCls = $('.formInputCls-'+index).val();
                    if(this.isInteger(formInputCls)){
                        this.$refs.pinInputs[index + 1].focus();
                    }else{
                        $('.formInputCls-'+index).val('')
                    }
                }else{
                    var formInpusdstCls = $('.formInputCls-'+index).val();
                    if(this.isInteger(formInpusdstCls)){
                        // this.$refs.pinInputs[index + 1].focus();
                    }else{
                        $('.formInputCls-'+index).val('')
                    }
                }
            },
            handleBackspace(index) {
                    // If backspace is pressed & the field is empty, move to previous input
                if (!this.form.pin[index] && index > 0) {
                    this.$refs.pinInputs[index - 1].focus();
                }
                
                // If all fields are empty, move focus to the first input
                if (this.form.pin.every((digit) => digit === "")) {
                    this.$refs.pinInputs[0].focus();
                }
            },
            async recaptchaAndSignIn() {
                const isValid = await  this.v$.form.$validate();
                if (isValid) {

                    this.loginLoader = 2;
                    await this.recaptcha(); // Call `recaptcha()` from `setup()`
                    this.signIn(); // Call `signIn()` after getting the token
                }
            },
            async signIn() {   
                this.startLoader()    
                this.$store.state.frontUser.loadUserInfoData = 2
                await axios.post(process.env.VUE_APP_FRONT_API_URL+'login', {
                    user_id: this.form.user_id,
                    mpin: Number(this.form.pin.join(""))
                })
                    .then((resp) => {
                        if (resp.status == 200 && resp.data.status == 'success') {
                            this.$toast.success(resp.data.message);
                            this.setItemWithExpiry("setTime", 'setCustomerLoginTime', 300);
                            localStorage.setItem("supportSystemFront", resp.data.data.userDetail._id);
                            localStorage.setItem("supportSystemNameFront", this.form.user_id);
                            localStorage.setItem("supportSystemMPinFront", Number(this.form.pin.join("")));
                            this.$store.state.frontUser.userInfo = resp.data.data.userDetail
                            this.$store.state.frontUser._id = resp.data.data.userDetail._id
                            this.$store.state.frontUser.isActiveToken = resp.data.data.userDetail._id
                            this.$store.state.frontUser.ticketRecords = resp.data.data.records
                            this.$store.state.frontUser.totalRecord = resp.data.data.totalRecord
                            if(this.$store.state.frontUser.totalRecord > 0){
                                this.$store.state.frontUser.loadUserInfoData = 3
                            }else{
                                this.$store.state.frontUser.loadUserInfoData = 1
                            }
                            this.$store.state.loadUserInfoData = 2
                            this.loginLoader = 1;
                            this.$router.push({ path: '/ticket-listing' })
                        } else if (resp.status == 200 && resp.data.status == 'failure') {
                            this.loginLoader = 1;
                            this.$toast.error(resp.data.message);
                        } else if (resp.status == 401) {
                            this.loginLoader = 1;
                            this.$toast.error(resp.data.message);
                        }
                        this.finishLoader()
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.errorRes = error.response.data.message;
                })
            },
        },
        validations() {
            return {
                form: {
                    user_id: {
                        required: helpers.withMessage('Please enter user id', required),
                        min: minLength(2),
                        noSpaces: helpers.withMessage('Spaces are not allowed', (value) => !/\s/.test(value))
                    },
                    /* mpin: {
                        required: helpers.withMessage('Please enter pin', required),
                        minLength: helpers.withMessage('PIN must be 4 digits', minLength(4)),
                        maxLength: helpers.withMessage('PIN must be 4 digits', maxLength(4)),
                        numeric: helpers.withMessage('Only numbers are allowed', (value) => /^\d+$/.test(value)),
                        noSpaces: helpers.withMessage('Spaces are not allowed', (value) => !/\s/.test(value))
                    }, */
                    // mpin: { // Validate mpin (joined string)
                    //     required: helpers.withMessage('Please enter a 4-digit PIN', required),
                    //     minLength: helpers.withMessage('PIN must be 4 digits', minLength(4)),
                    //     maxLength: helpers.withMessage('PIN must be 4 digits', maxLength(4)),
                    //     numeric: helpers.withMessage('Only numbers are allowed', (value) => /^\d+$/.test(value)),
                    // },
                    pin: {
                        required: helpers.withMessage("Please enter a 4-digit PIN", required),
                        minLength: helpers.withMessage("PIN must be 4 digits", minLength(4)),
                        maxLength: helpers.withMessage("PIN must be 4 digits", maxLength(4)),
                        numeric: helpers.withMessage(
                            "Only numbers are allowed",
                            (value) => value.every((digit) => /^\d$/.test(digit))
                        ),
                    },
                },
            }
        },
        
    }
onMounted(() => {
    // Move reCAPTCHA badge to the left
    const badge = document.querySelector(".grecaptcha-badge");
    if (badge) {
        badge.style.left = "10px";
        badge.style.right = "auto";
    }
});
</script>
<style >
.spanInfoCls{
    font-size: 10px;
    color: #436464;
}
.pin-inputs {
  display: flex;
  gap: 5px;
}
/* .forminput {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.error-msg {
  color: red;
  font-size: 14px;
} */
/* Ensure Right-to-Left layout */
/* .rtl-container {
  direction: rtl;
  text-align: right;
}

.grecaptcha-badge {
  left: 10px !important;
  right: auto !important;
  direction: rtl;
} */
</style>