
import { createApp } from 'vue'
import App from './App.vue'

import router from './router/router'
import store from './vuex/store'
import Toaster from '@meforma/vue-toaster';
import VueProgressBar from "@aacassandra/vue3-progressbar";
import Vuesax from 'vuesax3'
import 'vuesax3/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import timeago from 'vue-timeago3'

import "bootstrap-icons/font/bootstrap-icons.css";
// Import Frontend CSS
import '../src/assets/css/frontend.css';
import { enUS } from 'date-fns/locale';
import { VueReCaptcha } from "vue-recaptcha-v3";

// Import Bootstrap JS (optional, for components like modals, tooltips)
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const options = {
    color: "#36b9cc",
    failedColor: "#000",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
};
const customLocale = {
    ...enUS,
    formatDistance: (token, count, options) => {
      const result = enUS.formatDistance(token, count, options);
      // Remove the "about" prefix if it exists
      return result.replace(/^about /, '');
    },
  };
const app = createApp(App)
app.use(router)
app.use(Vuesax)
app.use(store)
app.use(VueReCaptcha, { siteKey: "6LfvcM0qAAAAAK541AZtZ0fue6fo7syFYw1mkAyn", 
  loaderOptions: {
  autoHideBadge: true, // Optional: Automatically hides the badge
  explicitRenderParameters: {
      badge: 'bottomleft', //incase you don't want to hide it
  }
}})

// app.use(timeago)
// app.use(timeago,  timeagoOptions) 
app.use(timeago, {
    locale: customLocale,
  });
// app.use(Toaster)
app.use(Toaster, {
  maxToasts: 1, // Ensures only one toast is shown at a time
  newestOnTop: true
});
app.use(VueProgressBar, options)
app.mount('#app')

// import App from './App.vue'

// createApp(App).mount('#app')