<template>
   <span class="d-none">RoleData</span>
</template>
<script>
import 
    { 
        getItemWithExpiry,
    }
 from '../helper'
import axios from 'axios'
    export default {
        name: 'FrontPage',
        computed: {
            iRt() {
                return this.$store.state.loadUserInfoData
            },
        },
        watch: {
            async iRt(newValue) {
                await this.refreshToken()
                return newValue
            },
        },
        async mounted() { 
            this.$store.state.isAdmin = 1   
            this.refreshToken()
        },
        methods: {
            getItemWithExpiry,
            startLoader () {
                this.$Progress.start()
            },
            finishLoader () {
                this.$Progress.finish()
            },
            async refreshToken() {
                const setTime = this.getItemWithExpiry("setTime");
                if(!setTime){
                    localStorage.removeItem("supportSystemFront");
                    localStorage.removeItem("supportSystemNameFront");
                    localStorage.removeItem("supportSystemMPinFront");
                    localStorage.removeItem("setTime");

                    this.$store.state.frontUser.userInfo = ''
                    this.$store.state.frontUser._id = ''
                    this.$store.state.frontUser.isActiveToken = ''
                    this.$store.state.frontUser.ticketRecords = ''
                    this.$store.state.frontUser.totalRecord = ''
                    this.$store.state.frontUser.loadUserInfoData = 1
                    this.$store.state.loadUserInfoData = 2
                    this.$toast.clear();
                    // this.$toast.success('Please login again');
                    this.$router.push({
                        name: 'Login'
                    })
                }
                if (this.$store.state.loadUserInfoData === 1) {
                    let token = localStorage.getItem('supportSystemFront');
                    let tokenName = localStorage.getItem('supportSystemNameFront');
                    let tokenPin = localStorage.getItem('supportSystemMPinFront');
                    if (token) {
                        this.startLoader()
                        this.$store.state.frontUser.isActiveToken = token
                        this.$store.state.frontUser.loadUserInfoData = 2
                        await axios.post(process.env.VUE_APP_FRONT_API_URL + 'login', {
                            user_id: tokenName,
                            mpin: tokenPin,
                            label_id: this.$store.state.labelIds
                        })
                        .then((resp) => {
                            if (resp.status == 200 && resp.data.status == 'success') {
                                localStorage.setItem("supportSystemFront", resp.data.data.userDetail._id);
                                localStorage.setItem("supportSystemNameFront", tokenName);
                                this.$store.state.frontUser.userInfo = resp.data.data.userDetail
                                this.$store.state.frontUser._id = resp.data.data.userDetail._id
                                this.$store.state.frontUser.isActiveToken = resp.data.data.userDetail._id
                                this.$store.state.frontUser.ticketRecords = resp.data.data.records
                                this.$store.state.frontUser.totalRecord = resp.data.data.totalRecord
                                if(this.$store.state.frontUser.totalRecord > 0){
                                    this.$store.state.frontUser.loadUserInfoData = 3
                                }else{
                                    this.$store.state.frontUser.loadUserInfoData = 1
                                }
                                this.$store.state.loadUserInfoData = 2
                                this.loginLoader = 1;
                            } else if (resp.status == 200 && resp.data.status == 'failure') {
                                this.loginLoader = 1;
                                this.$toast.error(resp.data.message);
                                localStorage.removeItem("supportSystemFront");
                                localStorage.removeItem("supportSystemNameFront");
                                localStorage.removeItem("supportSystemMPinFront");
                                localStorage.removeItem("setTime");
                                this.$store.state.frontUser.userInfo = ''
                                this.$store.state.frontUser._id = ''
                                this.$router.push({ path: '/' })
                            } else if (resp.status == 401) {
                                this.loginLoader = 1;
                                this.$toast.error(resp.data.message);
                            }
                            this.finishLoader()
                        })
                        .catch(error => {
                            this.errorMessage = error.message;
                            this.$store.state.user.accessToken = ''
                            this.$store.state.user.userInfo = ''
                            localStorage.removeItem("userInfo");
                            localStorage.removeItem("token");    
                            this.$router.push({
                                name: 'Login'
                            })
                        })
                    }else{
                        // this.$toast.success('Your login session has expired for security reasons. Please log in again to continue.')
                        this.$router.push({
                            path: '/'
                        })
                    }
                }
            }
        },
    }
</script>