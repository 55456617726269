export function formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
        return '0'
    }
}

export function abbrNum(number, decPlaces) {
    // return new Promise((resolve) => {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10,decPlaces);

        // Enumerate number abbreviations
        var abbrev = [ "K", "M", "B", "T" ];

        // Go through the array backwards, so we do the largest first
        for (var i=abbrev.length-1; i>=0; i--) {

            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10,(i+1)*3);

            // If the number is bigger or equal do the abbreviation
            if(size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number*decPlaces/size)/decPlaces;

                // Handle special case where we round up to the next abbreviation
                if((number == 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                // Add the letter for the abbreviation
                number += abbrev[i];

                // We are done... stop
                break;
            }
        }
        if(number){
            return number
        }else{
            return 0
        }
        // resolve(number);
    // })
}

import bigInt from "big-integer";

const BASE62_ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

// ✅ Encode Hex to Base62 (Without BigInt)
export function encodeBase62(hex) {
    let num = bigInt(hex, 16); // Use `bigInt` for large numbers
    let base62 = "";

    while (num.gt(0)) {
        base62 = BASE62_ALPHABET[num.mod(62)] + base62;
        num = num.divide(62);
    }

    return base62 || "0";
}

// ✅ Decode Base62 back to Hex (Without BigInt)
export function decodeBase62(base62) {
    let num = bigInt(0);

    for (let char of base62) {
        num = num.times(62).plus(BASE62_ALPHABET.indexOf(char));
    }

    let hex = num.toString(16); // Convert bigInt back to hex
    return hex.padStart(hex.length + (hex.length % 2), "0"); // Ensure even-length hex
}

export function formatDateTime(dateTimeString) {
    if(dateTimeString){

        const date = new Date(dateTimeString);
        
        // Extracting day, month, and year
        const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        
        // Extracting hours and minutes
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        // AM or PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
        
        return `${day}-${month}-${year} ${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
    }else{
        return '';
    }
}
export function setItemWithExpiry(key, value, expiryInMinutes) {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + expiryInMinutes * 60 * 1000, // Convert minutes to milliseconds
    };
    localStorage.setItem(key, JSON.stringify(item));
    return item;
}

export function getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    
    const item = JSON.parse(itemStr);
    const now = new Date();
    
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null; // Data has expired
    }
    
    return item.value;
}