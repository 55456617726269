<template>
  <Front />
  <!-- <Admin v-else/> -->
</template>

<script>
import Front from './fontend/Front.vue'
// import Admin from './backend/Admin.vue'
export default {
  name: 'App',
  components: {
    // Admin,
    Front
  },
  async mounted() { 
    // let frontToken = localStorage.getItem('supportSystemFront');
    // let adminToken = localStorage.getItem('supportSystemAdmin');
    // let isAdmin = window.location.href.includes("/admin");
    // if (frontToken) {
    //   this.$store.state.isAdmin = 1
    // }else if (adminToken){
    //   this.$store.state.isAdmin = 2
    // }else if (isAdmin){
    //   this.$store.state.isAdmin = 2
    // }else{    
    //   this.$store.state.isAdmin = 1
    // }
    this.$store.state.isAdmin = 1

            // if(this.$store.state.isAdmin == 2){
            //     this.$router.push({
            //         name: 'Admin'
            //     })
            // }   
  },
}
</script>
