<template>
    <header>
        <span></span>
        <h1><img src="../assets/images/logo.svg" alt="" ></h1>
        <span>
            <router-link to="" @click="logout()">
                <LogOutFrontSvgIcon />
            </router-link></span>
    </header>  
    <div class="form_wrap ticketlisting">
        <div class="card  mb-2">
            <div class="card-body text-center">
                <h4>Welcome to <span>{{ $store.state.frontUser.userInfo.user_id }}</span> Support Ticket System!</h4>
                <p>Looks like you are  logged in</p>
            </div>
        </div>
        <div class="listing_main_wrap">
            <div class="listing_main_header d-flex w-100">
                <label>My Tickets</label>
                <Select2 
                    v-model="myValue" 
                    id="cars" 
                    :placeholder="'My Tickets'" 
                    :options="$store.state.labels.LabelsList" 
                    :settings="{ settingOption: value, settingOption: value }"
                    @change="myChangeEvent($event)" 
                    @select="mySelectEvent($event)" 
                />
            </div>
            <div class="tickets_group_set">
                <div class="no-tickets" v-if="$store.state.frontUser.loadUserInfoData == 1" >
                    <img src="../assets/images/no-tickets.svg" />
                </div>
                <div class="tickets_group_set_listing"  v-else>
                    <ul  v-if="$store.state.frontUser.loadUserInfoData == 2">
                        <li class="shrmain-bgimage" v-for="index in 6" :key="index">
                            <router-link class="">
                                <div class="left_list_tckt">
                                    <h5 class="stroke animate title"></h5>
                                    <p class="m-0 stroke animate title w-50"></p>
                                </div>
                                <div class="right_list_tckt">
                                    <span class="badge stroke animate title"></span>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                    <ul v-if="$store.state.frontUser.loadUserInfoData == 3">
                        <li v-for="(item) in $store.state.frontUser.ticketRecords" :key="item._id">
                            <router-link :to="`/ticket-detail/${encodeBase62(item._id)}`" class="">
                                <div class="left_list_tckt">
                                    <h5>{{ item.title }}</h5>
                                    <p class="m-0" v-b-tooltip.hover v-bind:title="formatDateTime(item.createdAt)">
                                        <timeago v-if="item.createdAt" :datetime="new Date(item.createdAt)"/>
                                    </p>
                                </div>
                                <div class="right_list_tckt">
                                    <span class="badge " :class="item.color_code">{{ item.label }}</span>
                                </div>
                            </router-link>
                        </li>  
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <footer>
        <router-link to="/ticket-create" class="field_btn">Create Ticket</router-link>
    </footer>
    <LabelsData />
</template>
<script>
import LabelsData from './labels/LabelsData.vue';
import Select2 from 'vue3-select2-component';
import LogOutFrontSvgIcon from '@/components/Widgets/Icon/LogOutFrontSvgIcon.vue';
import {
    encodeBase62,
    formatDateTime
} from '../helper'
    export default {
        name: 'TicketListingPage',
        components: {
            Select2,
            LabelsData,
            LogOutFrontSvgIcon
        },
        data() {
            return {
                myValue: '',
            }
        },
        async mounted() { 
            let token = localStorage.getItem('supportSystemFront');
            if (!token) {
                this.$store.state.user.accessToken = ''
                //  this.$store.state.frontUser.userInfo = resp.data.data.userDetail
                //             this.$store.state.frontUser._id = resp.data.data.userDetail._id
            // }else{
            //     this.$router.push({ path: '/ticket-listing' })
            }
            this.$store.state.isAdmin = 1   
            this.$store.state.frontUser.isActiveToken = '6791f358f9d69486fdbf5460'
            // if(this.$store.state.adminLogin == 2){
            //     this.$router.push({ path: '/admin' })  
            // }   
        },
        methods: {
            encodeBase62,
            formatDateTime,            
            logout(){
                localStorage.removeItem("supportSystemFront");
                localStorage.removeItem("supportSystemNameFront");
                localStorage.removeItem("supportSystemMPinFront");
                localStorage.removeItem("setTime");
                this.$store.state.frontUser.userInfo = ''
                this.$store.state.frontUser._id = ''
                this.$router.push({ path: '/' })
            },
            myChangeEvent(val){
                console.log(val);
            },
            mySelectEvent({id, text}){
                this.$store.state.labelIds= id
                this.$store.state.loadUserInfoData = 1;          
                console.log({id, text})
            },
        }

    }
</script>