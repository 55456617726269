import { createStore } from "vuex";
import {UserModule} from './module/UserModule'
import { PriorityModule } from "./module/PriorityModule";
import { LabelsModule } from "./module/LabelsModule";
import { FrontUserModule } from "./module/FrontUserModule";
import { RoleModule } from "./module/RoleModule";
import moment from "moment";
export default createStore({
    state: {
        pageTitle: 'Dashboard',
        // isAdmin: 1,
        adminLogin: 1,
        attemptLogin: 1,
        loadUserInfoData: 1,
        labelIds: '',
        showEntries: 15,
        stausOptions: [{ id: 2, text: 'All' }, { id: true, text: 'Active' }, { id: false, text: 'Inactive' }],
        ticketListStartDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        ticketListEndDate: moment().format("YYYY-MM-DD"),
        dashboardAdminList: [],
        dashboardAdminfullTotal: 0,
        dashboardAdmincustomerTotal: 0,
    },
    modules: {
        user: UserModule,
        role: RoleModule,
        priority: PriorityModule,
        labels: LabelsModule,
        frontUser: FrontUserModule,
    }
})