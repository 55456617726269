<template>
    <div class="ticketsubmited">
       <div class="submitted_ticketrow">
            <h4>Ticket Submitted!</h4>
            <div class="iconz">                
                <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38.8553 64.0534C39.7898 64.2788 39.8754 65.5477 38.9652 65.8622L33.5906 67.6959C20.0861 72.2094 12.9767 68.4364 8.58857 54.4373L4.23448 40.5088C-0.119615 26.5098 3.48612 19.1047 16.9906 14.5912L18.773 13.9793C20.1434 13.5088 21.4771 14.9336 21.088 16.3747C20.8951 17.089 20.7096 17.8345 20.5283 18.6111L17.1947 33.3859C13.4529 49.9943 18.9295 59.1624 34.9512 63.1118L38.8553 64.0534Z" fill="#45C49C"/>
                <path d="M54.1364 4.99993L48.4557 3.62471C37.0943 0.839004 30.325 3.13104 26.3451 11.6645C25.3246 13.8154 24.5082 16.4248 23.8279 19.4221L20.4943 34.1969C17.1607 48.9364 21.5488 56.2004 35.7336 59.6914L41.4483 61.1019C43.4213 61.5955 45.2582 61.9129 46.959 62.0539C57.5721 63.1118 63.2188 57.9635 66.0762 45.2339L69.4098 30.4944C72.7434 15.7549 68.3893 8.45561 54.1364 4.99993ZM47.7414 40.6851C47.4352 41.884 46.4147 42.6598 45.2582 42.6598C45.0541 42.6598 44.85 42.6245 44.6119 42.5893L34.7131 39.9799C33.3525 39.6273 32.5361 38.1815 32.8762 36.771C33.2164 35.3606 34.6111 34.5143 35.9717 34.8669L45.8705 37.4763C47.2651 37.8289 48.0815 39.2746 47.7414 40.6851ZM57.7082 28.7666C57.402 29.9655 56.3815 30.7412 55.225 30.7412C55.0209 30.7412 54.8168 30.706 54.5787 30.6707L38.0807 26.3335C36.7201 25.9809 35.9037 24.5351 36.2438 23.1246C36.584 21.7141 37.9787 20.8679 39.3393 21.2205L55.8373 25.5577C57.2319 25.8751 58.0483 27.3208 57.7082 28.7666Z" fill="#45C49C"/>
                </svg>
            </div>
            <p>Please wait a minute, our awesome helper will be with you shortly :)</p>
            <div class="setbuttondiv mt-4">
                <router-link to="/ticket-listing" class="field_btn">Okay Thanks</router-link>
            </div>
       </div>
    </div>
</template>
<script>
    export default {
        name: 'TicketSubmitPage',
    }
</script>