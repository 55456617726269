<template>
    <span class="d-none">LabelsData</span>
</template>
<script>
import axios from 'axios'
    export default {
        name: 'LabelsDataPage',
        data() {
        return {
            labelOptions: [],
            labelFilterOptions: [],
        }
    },
    async mounted() {
        if (this.$store.state.frontUser.isActiveToken == '') {
            // console.log(this.currentDate);
        } else {
            await this.labelDataList();
        }
    },
    computed: {
        iAT() {
            return this.$store.state.frontUser.isActiveToken
        },
    },
    watch: {
        async iAT(newValue) {
            if (this.$store.state.frontUser.isActiveToken != '') {
                await this.labelDataList();
            }
            return newValue
        },
    },
    methods: {
        async labelDataList() {
            if (this.$store.state.frontUser.isActiveToken != '' && this.$store.state.labels.loadLabelsData === 1) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                await axios.get(process.env.VUE_APP_API_URL + 'label/active')
                .then((resp) => {
                    if (resp.status == 200) {
                            const labelsOpts = resp.data.data.records
                            this.labelOptions = [];
                            this.labelFilterOptions = [];
                            var len = labelsOpts.length;
                            for (var i = 0; i < len; i++) {
                                this.labelOptions.push({
                                    id: labelsOpts[i]._id,
                                    text: labelsOpts[i].label
                                });
                                this.labelFilterOptions.push({
                                    value: labelsOpts[i]._id,
                                    label: labelsOpts[i].label
                                });
                            }
                            this.$store.state.labels.loadLabelsData = 2
                            this.$store.state.labels.LabelsList = this.labelOptions
                            this.$store.state.labels.LabelsFilterList = this.labelFilterOptions
                        }
                    })
                    .catch(error => {
                        console.error("There was an error!", error);
                        console.log("response", error.response.status);
                        console.log("message", error.response.data.message);
                    })
            }
        },
    },
    }
</script>